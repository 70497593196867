import HttpService from "../http/HttpService";

class FileService {

    getExampleImportFile() {
        return HttpService.get('/files/importExample');
    }

    downloadExampleFile(code){
        return HttpService.get('/files/downloadExampleFile/'+code);
    }

    downloadFile(body){
        return HttpService.post('/files/downloadFile', body);
    }

   
}
// eslint-disable-next-line
export default new FileService();