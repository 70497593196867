import HttpService from "../http/HttpService";

class CountryService {

    getAll() {
        return HttpService.get('/countries/');
    }

}
// eslint-disable-next-line
export default new CountryService();