import React from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {removeHtmlTags} from "../../utils/stringUtils";
import i18n from '../../messages/i18n';

class MessageLogComponent extends React.Component {

    render() {
        const dateFormatOptions = {
            formatMatcher: "basic",
            year: 'numeric',
            month: 'numeric',
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short",
            timeZone: this.props.timeZone
        };

        return(<Container className="customForm">
            <Row className="mb-2">
                <Col className="d-flex justify-content-center">
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!this.props.dataLoading}/>
                </Col>
            </Row>
            {this.props.data && this.props.data.map((message, index) => (<>
                <Row>
                    <Form.Group className="message-log" as={Col}>
                        <Form.Label>{index + 1}. <b>{Intl.DateTimeFormat(i18n.language, dateFormatOptions).format(new Date(message.sentAt))}</b> {message.recipient}</Form.Label>
                        <Form.Control type="text" name="messageTitle" value={message.title} disabled/>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        <Form.Control as="textarea" name="messageContent" value={removeHtmlTags(message.content)} rows={4} disabled/>
                    </Col>
                </Row>
                <br/>
            </>))}
        </Container>);
    }
}

export default MessageLogComponent;
