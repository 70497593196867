import React from "react";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

const IconSorting = (props) => {
  return (
    <>
        {(props.sorting === props.selector && props.direction === "asc") ? (
            <FaSortUp onClick={() => {props.handleSorting(props.selector)}}/>
        ) : (props.sorting === props.selector && props.direction === "desc") ? (
            <FaSortDown onClick={() => {props.handleSorting(props.selector)}}/>
        ) : (
            <FaSort onClick={() => {props.handleSorting(props.selector)}}/>
        )}
      </>
  );
}
export default IconSorting;
