import React from "react";
import {withTranslation} from "react-i18next";
import {Button, Container, Stack, Table} from 'react-bootstrap';
import {Navigate} from 'react-router-dom';
import OrderService from "../../services/OrderService";
import ErrorModal from "../../commons/modal/ErrorModal";
import SpinnerModal from "../../commons/modal/SpinnerModal";
import Select from "react-select";
import UserService from "../../services/UserService";
import InfoModal from "../../commons/modal/InfoModal";
import {formatErrorMessage} from "../../utils/stringUtils";
import {getCurrentUser} from "../../utils/storageUtils";

class AssignDealersComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSpinnerMessage: null,
            showInfoMessage: null,
            showErrorMessage: null,
            orders: [],
            total: null,
            dealers: {},
            isLoadingDealers: false,
            assignedDealers: {}
        }
    }

    getData = () => {
        // load orders in foreground
        this.showSpinner(this.props.t('common:modalMessage.getData'));
        OrderService.getOrders({
            parametersDTO: {
                page: 1,
                count: 50,
                sorting: "locationIdCrm",
                direction: "asc"
            },
            state: "CREATED"
        }).then((response) => {
            this.setState({
                showSpinnerMessage: null,
                orders: this.translateOrderState(response.data.list),
                total: response.data.total
            })
        }, (error) => {
            this.showError(error.response.data + " (" + error.response.status + ")");
        });

        // load dealers in background
        this.setState({isLoadingDealers: true });
        UserService.getActiveDealers().then((response) => {
            const dealers = {};
            response.data.forEach((i) => {
                const networkName = i.name.substring(7);
                dealers[networkName] = i.members;
            });
            this.setState({
                isLoadingDealers: false,
                dealers: dealers
            });
        }, (error) => {
            this.setState({isLoadingDealers: false });
        });
    }

    assignDealers = () => {
        this.showSpinner(this.props.t('common:modalMessage.saveData'));
        UserService.assignDealers(this.state.assignedDealers).then((success) => {
            this.showInfo(success.data);
        }, (error) => {
            this.showError(formatErrorMessage(error.response.data, error.response.status));
        });
    }

    translateOrderState = (orders) => {
        orders.forEach((order) => (order.orderState = this.props.t('orders:state.' + order.orderState)));
        return orders;
    }

    handleAssignDealer = (order, dealer) => {
        this.setState({
            assignedDealers: {
                ...this.state.assignedDealers,
                [order.id]: dealer
            }
        });
    };

    showSpinner = (message) => {
        this.setState({
            showSpinnerMessage: message,
            showErrorMessage: null
        });
    }

    showInfo = (message) => {
        this.setState({
            showSpinnerMessage: null,
            showInfoMessage: message
        });
    }

    showError = (message) => {
        this.setState({
            showSpinnerMessage: null,
            showErrorMessage: message
        });
    }

    hideSpinner = () => {
        this.setState({
            showSpinnerMessage: null
        });
    }

    hideInfo = () => {
        this.setState({
            showInfoMessage: null,
            orders: [],
            total: null,
            dealers: {},
            assignedDealers: {}
        });
        this.getData();
    }

    hideError = () => {
        this.setState({
            showErrorMessage: null
        })
    }

    componentDidMount = () => {
        this.getData();
    }

    render() {
        const currentUser = getCurrentUser();
        if (!currentUser || !currentUser.operator) return <Navigate to="/" replace />;

        const {t} = this.props;
        let rowNum = 1;
        return(<>
            <InfoModal modalShow={this.state.showInfoMessage != null} close={this.hideInfo} body={this.state.showInfoMessage} title={t('common:modalMessage.infoTitle')}/>
            <ErrorModal modalShow={this.state.showErrorMessage != null} close={this.hideError} body={this.state.showErrorMessage} title={t('common:modalMessage.errorTitle')}/>
            <SpinnerModal modalShow={this.state.showSpinnerMessage != null} title={this.state.showSpinnerMessage}/>
            <Container className="customForm">
                <h4>{t('assignDealers:title')}</h4><br/>
                <Table striped responsive bordered size="sm">
                    <thead><tr>
                        <th><b>{t('assignDealers:columns.rowNum')}</b></th>
                        <th><b>{t('assignDealers:columns.orderId')}</b></th>
                        <th><b>{t('assignDealers:columns.deviceType')}</b></th>
                        <th><b>{t('assignDealers:columns.companyNip')}</b></th>
                        <th><b>{t('assignDealers:columns.company')}</b></th>
                        <th><b>{t('assignDealers:columns.companyIdCrm')}</b></th>
                        <th><b>{t('assignDealers:columns.location')}</b></th>
                        <th><b>{t('assignDealers:columns.locationIdCrm')}</b></th>
                        <th><b>{t('assignDealers:columns.locationNetwork')}</b></th>
                        <th><b>{t('assignDealers:columns.preferredDealer')}</b></th>
                        <th style={{width:'20%'}}><b>{t('assignDealers:columns.assignedDealer')}</b></th>
                    </tr></thead>
                    <tbody>
                    {this.state.orders.map((order) => (<tr key={order.id}>
                        <td>{rowNum++}</td>
                        <td>{order.id}</td>
                        <td>{order.deviceType}</td>
                        <td>{order.companyNip}</td>
                        <td>{order.companyName}</td>
                        <td>{order.companyIdCrm}</td>
                        <td>{order.locationName}<br/>{order.locationStreet}, {order.locationCity}</td>
                        <td>{order.locationIdCrm}</td>
                        <td>{order.locationNetwork === "INDEPENDENT" ? t("order:locationForm.independent") : order.locationNetwork}</td>
                        <td>{order.preferredDealer}</td>
                        <td>
                            <Select
                                options={this.state.dealers[order.locationNetwork]}
                                onChange={(dealer) => this.handleAssignDealer(order, dealer)}
                                isLoading={this.state.isLoadingDealers}
                                getOptionLabel={(option) => `${option.firstName} ${option.lastName} ${option.email}, ID CRM: ${option.attributes.idCrm ? option.attributes.idCrm[0] : ''}, ${t('common:phone')}: ${option.attributes.phone ? option.attributes.phone[0] : ''}`}
                                placeholder={t('assignDealers:selectDealer')}
                                noOptionsMessage={() => t('assignDealers:noDealersAvailable')}
                                loadingMessage={() => t('common:loading')}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999, width: '350px', fontSize: '80%' }) }}
                            />
                        </td>
                    </tr>))}
                    </tbody>
                </Table>
            </Container>
            <Container className="mt-4" style={{paddingRight:0 , paddingLeft: 0}}>
                <Stack direction="horizontal" gap={3}>
                    <Button onClick={this.assignDealers}>{t('common:button.save')}</Button>
                </Stack>
            </Container>
        </>);
    }
}

export default withTranslation()(AssignDealersComponent);