import HttpService from "../http/HttpService";

class MaintenanceService {

    getInstanceInfo() {
        return HttpService.get('/maintenance/instanceInfo');
    }

}
// eslint-disable-next-line
export default new MaintenanceService();