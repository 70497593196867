import HttpService from "../http/HttpService";

class OrderService {

    getOrder(id) {
        return HttpService.get('/orders/' + id);
    }

    updateOrder(id, body) {
        return HttpService.put('/orders/' + id, body);
    }

    getOrders(body) {
        return HttpService.post('/orders/list', body);
    }

    importOrders(body) {
        return HttpService.post('/orders/import', body);
    }

    beginOrder(id) {
        return HttpService.post('/orders/' + id + "/begin");
    }

    unassignOrder(id, note) {
        return HttpService.post('/orders/' + id + "/unassign", {note: note});
    }

    addComment(id, note) {
        return HttpService.post('/orders/' + id + "/comment", {note: note});
    }

    suspendOrder(id, note) {
        return HttpService.post('/orders/' + id + "/suspend", {note: note});
    }

    unsuspendOrder(id) {
        return HttpService.post('/orders/' + id + "/unsuspend");
    }

    exportOrder(id) {
        return HttpService.post('/orders/' + id + "/export");
    }

    closeOrder(id) {
        return HttpService.post('/orders/' + id + "/close");
    }

    getOrderStateHistory(id) {
        return HttpService.get('/orders/' + id + "/stateHistory");
    }

    getOrderMessageLog(id) {
        return HttpService.get('/orders/' + id + "/messageLog");
    }

    resignFromOrder(id, note) {
        return HttpService.post('/orders/' + id + "/resign", {note: note});
    }

    archiveOrder(id) {
        return HttpService.post('/orders/' + id + "/archive");
    }

    deArchiveOrder(id) {
        return HttpService.post('/orders/' + id + "/deArchive");
    }
}
// eslint-disable-next-line
export default new OrderService();