import HttpService from "../http/HttpService";

class DeviceTypeService {

    getAll() {
        return HttpService.get('/deviceTypes/');
    }

}
// eslint-disable-next-line
export default new DeviceTypeService();