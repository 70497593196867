import HttpService from "../http/HttpService";

class SnippetService {

    getSnippet(code) {
        return HttpService.get('/snippets/' + code);
    }

}
// eslint-disable-next-line
export default new SnippetService();