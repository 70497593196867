import HttpService from "../http/HttpService";

class ReportService {

    getFullReport() {
        return HttpService.get('/reports/full');
    }

}
// eslint-disable-next-line
export default new ReportService();