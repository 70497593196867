import React from "react";
import { Outlet } from "react-router-dom";

const ContentComponent = (props) => {
    const timeZone = props.timeZone;
    return(
        <div>
            <Outlet context={[timeZone]} />
        </div>
    );
}

export default ContentComponent;