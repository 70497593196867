
class DownloadService {

    /**
     * Function for download a file that was obtained from API (FileResponseDTO):
     * {
     *     byte[] data,
     *     String fileName, // file name without extension
     *     String fileType, // file extension
     *     String mediaType,
     *     String charset
     * }
     * @param fileResponse response from API
     */
    downloadFromResponse = (fileResponse) => {
        const fileUrl = "data:" + fileResponse.data.mediaType + ";base64," + fileResponse.data.data;
        fetch(fileUrl).then(response => response.blob()).then(blob => {
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, {type: fileResponse.data.mediaType});
            link.download = fileResponse.data.fileName + "." + fileResponse.data.fileType;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

}
// eslint-disable-next-line
export default new DownloadService();