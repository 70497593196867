import HttpService from "../http/HttpService";

class UserService {

    login(email, password) {
        return HttpService.login(email, password);
    }

    sendResetPasswordNotification(email) {
        return HttpService.sendResetPasswordNotification(email);
    }

    resetPassword(token, email, password) {
        return HttpService.resetPassword(token, email, password);
    }

    getActiveDealers() {
        return HttpService.get("/users/dealers");
    }

    assignDealers(body) {
        return HttpService.post("/users/assign", body);
    }

    changeUserPreferences(language, timeZone) {
        return HttpService.post("/users/preferences", {language: language, timeZone: timeZone});
    }
}
// eslint-disable-next-line
export default new UserService();