import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

import order_pl from "./pl/order_pl.json"
import orders_pl from "./pl/orders_pl.json"
import orders_import_pl from "./pl/orders_import_pl.json"
import reports_pl from "./pl/reports_pl.json"
import sidebar_pl from "./pl/sidebar_pl.json"
import common_pl from "./pl/common_pl.json"
import assignDealers_pl from "./pl/assignDealers_pl.json"
import resetPassword_pl from "./pl/resetPassword_pl.json"

import order_en from "./en/order_en.json"
import orders_en from "./en/orders_en.json"
import orders_import_en from "./en/orders_import_en.json"
import reports_en from "./en/reports_en.json"
import sidebar_en from "./en/sidebar_en.json"
import common_en from "./en/common_en.json"
import assignDealers_en from "./en/assignDealers_en.json"
import resetPassword_en from "./en/resetPassword_en.json"

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    resources: {
        pl: {
            assignDealers : assignDealers_pl,
            order : order_pl,
            orders : orders_pl,
            orders_import: orders_import_pl,
            reports: reports_pl,
            sidebar : sidebar_pl,
            common: common_pl,
            resetPassword: resetPassword_pl
        },
        en: {
            assignDealers : assignDealers_en,
            order : order_en,
            orders : orders_en,
            orders_import: orders_import_en,
            reports: reports_en,
            sidebar : sidebar_en,
            common: common_en,
            resetPassword: resetPassword_en
        }
    },
    fallbackLng: "pl",
    debug: false,
    interpolation: {
         escapeValue: false 
    }
});

export default i18n;