import React from 'react';
import {withTranslation} from "react-i18next";
import { Modal, Spinner } from 'react-bootstrap';

const SpinnerModal = (props) => {

  return (
    <Modal show={props.modalShow}>
      <Modal.Header>
          <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center">
        <Spinner animation="border" />
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation() (SpinnerModal);