import axios from 'axios';
import i18n from '../messages/i18n';
import {getAuthData, removeAuthData, setAuthData} from "../utils/storageUtils";

class HttpService {

    constructor() {
        this.service = axios.create({
            baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
            timeout: process.env.REACT_APP_BACKEND_TIMEOUT
        });
    }

    login(email, password) {
        return this.service.post("/auth/login", { email: email, password: password }, {});
    }

    sendResetPasswordNotification(email) {
        return this.service.post("/users/resetPasswordNotification", { email: email }, {});
    }

    resetPassword(token, email, password) {
        return this.service.put("/users/resetPassword", { token: token, email: email, password: password }, {});
    }

    async get(path) {
        if (await this.checkToken()) return;
        return this.service.get(path, { headers: this.headers() });
    }

    async patch(path, payload) {
        if (await this.checkToken()) return;
        return this.service.patch(path, payload, { headers: this.headers() });
    }

    async post(path, payload) {
        if (await this.checkToken()) return;
        return this.service.post(path, payload, { headers: this.headers() });
    }

    async put(path, payload) {
        if (await this.checkToken()) return;
        return this.service.put(path, payload, { headers: this.headers() });
    }

    headers() {
        const headers = {
            "Accept-Language": i18n.language
        }
        const authData = getAuthData();
        if (authData) {
            headers.Authorization = "Bearer " + authData.accessToken;
        }
        return headers;
    }

    /**
     * Function checks token and refreshes it if necessary.
     * @return true if jump to login page was issued
     */
    async checkToken() {
        let authData = getAuthData();
        if (!authData) return;
        // if access token has expired
        if (Date.now() > authData.accessTokenExpires - 5000) { // 5 sec margin
            // if refresh token has expired
            if (Date.now() > authData.refreshTokenExpires - 5000) { // 5 sec margin
                removeAuthData();
                window.location.href = "/";
                return true;
            } else { // refresh token is valid
                try {
                    const result = await this.service.post("/auth/refreshToken", {refreshToken: authData.refreshToken});
                    setAuthData(result.data);
                    console.info("token refreshed");
                } catch (e) {
                    removeAuthData();
                    window.location.href = "/";
                    return true;
                }
            }
        }
    }
}
// eslint-disable-next-line
export default new HttpService();