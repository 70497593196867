import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Container, Row, Spinner, Table} from "react-bootstrap";
import i18n from '../../messages/i18n';

class OrderStateHistoryComponent extends React.Component {

    render() {
        const { t } = this.props;
        const dateFormatOptions = {
            formatMatcher: "basic",
            year: 'numeric',
            month: 'numeric',
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short",
            timeZone: this.props.timeZone
        };

        let rowNum = 1;

        return(<Container className="customForm">
            <Row className="mb-2">
                <Col className="d-flex justify-content-center">
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!this.props.dataLoading}/>
                </Col>
            </Row>
            {this.props.data && this.props.data.length > 0 && (<>
                <Table striped responsive bordered size="sm">
                    <thead><tr>
                        <td><b>{t('order:stateHistory.columns.rowNum')}</b></td>
                        <td><b>{t('order:stateHistory.columns.timestamp')}</b></td>
                        <td><b>{t('order:stateHistory.columns.authorName')}</b></td>
                        <td><b>{t('order:stateHistory.columns.authorEmail')}</b></td>
                        <td><b>{t('order:stateHistory.columns.state')}</b></td>
                    </tr></thead>
                    <tbody>
                    {this.props.data.map((change) => (<tr>
                        <td>{rowNum++}</td>
                        <td>{Intl.DateTimeFormat(i18n.language, dateFormatOptions).format(new Date(change.timestamp))}</td>
                        <td>{change.authorName + ' ' + change.authorSurname}</td>
                        <td>{change.authorEmail}</td>
                        <td>{t('orders:state.' + change.newValue)}</td>
                    </tr>))}
                    </tbody>
                </Table>
            </>)}
        </Container>);
    }
}

export default withTranslation() (OrderStateHistoryComponent);
