// For IANA list of time zones: Intl.supportedValuesOf('timeZone')
// For browser timezone: Intl.DateTimeFormat().resolvedOptions().timeZone

import {getCurrentUser} from "./storageUtils";

export function getTimeZoneList() {
    return [
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        "WET", // Western Europe Time
        "CET", // Central Europe Time
        "EET", // Eastern Europe Time
        "UTC"
    ];
}

export function getUserTimeZone() {
    const currentUser = getCurrentUser();
    if (!currentUser) return Intl.DateTimeFormat().resolvedOptions().timeZone;
    return currentUser.attributes && currentUser.attributes.timeZone ? currentUser.attributes.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;
}