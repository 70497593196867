import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Row, Form} from "react-bootstrap";

class AddressComponent extends React.Component {

    render() {
        const { t } = this.props;
        return(<>
                {this.props.title && (<>
                    <Row className="panel-heading">
                        <Col sm={12}><h5>{this.props.title}</h5></Col>
                    </Row>
                    <br/>
                </>)}
                {this.props.showContact && (<>
                    <Row>
                        <Col md={4}>
                            <Col md={12}><Form.Label className="labelName">{t('order:addressForm.name')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name={this.props.prefix+'Name'} value={this.props.name}/></Col>
                        </Col>
                        <Col md={4}>
                            <Col md={12}><Form.Label className="labelName">{t('order:addressForm.phone')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name={this.props.prefix+'Phone'} value={this.props.phone}/></Col>
                        </Col>
                        <Col md={4}>
                            <Col md={12}><Form.Label className="labelName">{t('order:addressForm.email')}</Form.Label></Col>
                            <Col md={12}><Form.Control type="text" name={this.props.prefix+'Email'} value={this.props.email}/></Col>
                        </Col>
                    </Row>
                    <br/>
                </>)}
                <Row>
                    <Col md={2}>
                        <Col md={12}><Form.Label className="labelName">{t('order:addressForm.street')}</Form.Label></Col>
                        <Col md={12}><Form.Control type="text" name={this.props.prefix+'Street'} value={this.props.street} required/></Col>
                    </Col>
                    <Col md={2}>
                        <Col md={12}><Form.Label className="labelName">{t('order:addressForm.building')}</Form.Label></Col>
                        <Col md={12}><Form.Control type="text" name={this.props.prefix+'Building'} value={this.props.building} required/></Col>
                    </Col>
                    <Col md={2}>
                        <Col md={12}><Form.Label className="labelName">{t('order:addressForm.flat')}</Form.Label></Col>
                        <Col md={12}><Form.Control type="text" name={this.props.prefix+'Flat'} value={this.props.flat}/></Col>
                    </Col>
                    <Col md={2}>
                        <Col md={12}><Form.Label className="labelName">{t('order:addressForm.city')}</Form.Label></Col>
                        <Col md={12}><Form.Control type="text" name={this.props.prefix+'City'} value={this.props.city} required/></Col>
                    </Col>
                    <Col md={2}>
                        <Col md={12}><Form.Label className="labelName">{t('order:addressForm.postalCode')}</Form.Label></Col>
                        <Col md={12}><Form.Control type="text" name={this.props.prefix+'PostalCode'} value={this.props.postalCode} required/></Col>
                    </Col>
                    <Col md={2}>
                        <Col md={12}><Form.Label className="labelName">{t('order:addressForm.country')}</Form.Label></Col>
                        <Col md={12}>
                            <Form.Select name={this.props.prefix+'Country'} value={this.props.country}>
                                {this.props.countries.map(c => (
                                    <option value={c.code} key={c.code}>{c.code+' ('+c.name+')'}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Col>
                </Row>
        </>);
    }
}
export default withTranslation() (AddressComponent);