import HttpService from "../http/HttpService";

class NetworkService {

    getAll() {
        return HttpService.get('/networks/');
    }

}
// eslint-disable-next-line
export default new NetworkService();