export function getAuthData() {
    const authData = sessionStorage.getItem("auth_data");
    return authData ? JSON.parse(authData) : null;
}

export function removeAuthData() {
    sessionStorage.removeItem("auth_data");
}

export function setAuthData(authData) {
    if (!authData.accessTokenExpires) authData.accessTokenExpires = Date.now() + 1000 * authData.accessTokenDuration;
    if (!authData.refreshTokenExpires) authData.refreshTokenExpires = Date.now() + 1000 * authData.refreshTokenDuration;
    sessionStorage.setItem("auth_data", JSON.stringify(authData));
}

export function getInstanceInfo() {
    const info = sessionStorage.getItem("instance_info");
    return info ? JSON.parse(info) : null;
}

export function setInstanceInfo(info) {
    sessionStorage.setItem("instance_info", JSON.stringify(info));
}

export function getCurrentUser() {
    const authData = getAuthData();
    return authData ? authData.currentUser : null;
}
