import HttpService from "../http/HttpService";

class LocationService {

   getPin(orderId) {
       return HttpService.get('/orders/' + orderId + "/pin");
   }

}
// eslint-disable-next-line
export default new LocationService();