import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Container, Form, Row} from "react-bootstrap";
import i18n from '../../messages/i18n';

class OrderHistoryComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { t } = this.props;
        const dateFormatOptions = {
            formatMatcher: "basic",
            year: 'numeric',
            month: 'numeric',
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short",
            timeZone: this.props.timeZone
        };
        let createdAt = this.props.createdAt;
        if (createdAt) createdAt = Intl.DateTimeFormat(i18n.language, dateFormatOptions).format(new Date(createdAt));
        let updatedAt = this.props.updatedAt;
        if (updatedAt) updatedAt = Intl.DateTimeFormat(i18n.language, dateFormatOptions).format(new Date(updatedAt));
        let startedAt = this.props.startedAt;
        if (startedAt) startedAt = Intl.DateTimeFormat(i18n.language, dateFormatOptions).format(new Date(startedAt));
        let exportedAt = this.props.exportedAt;
        if (exportedAt) exportedAt = Intl.DateTimeFormat(i18n.language, dateFormatOptions).format(new Date(exportedAt));
        let closedAt = this.props.closedAt;
        if (closedAt) closedAt = Intl.DateTimeFormat(i18n.language, dateFormatOptions).format(new Date(closedAt));
        let resignedAt = this.props.resignedAt;
        if (resignedAt) resignedAt = Intl.DateTimeFormat(i18n.language, dateFormatOptions).format(new Date(resignedAt));

        return(<Container className="customForm">
            <Row className="mb-2">
                <Col md={3} className="d-flex justify-content-end"><Form.Label>{t('order:historyForm.createdDate')}</Form.Label></Col>
                <Col md={3}><span class="font-monospace">{createdAt}</span></Col>
                <Col md={3} className="d-flex justify-content-end"><Form.Label>{t('order:historyForm.lastUpdatedDate')}</Form.Label></Col>
                <Col md={3}><span className="font-monospace">{updatedAt}</span></Col>
            </Row>
            {startedAt &&
                <Row className="mb-2">
                    <Col md={3} className="d-flex justify-content-end"><Form.Label>{t('order:historyForm.startedDate')}</Form.Label></Col>
                    <Col md={3}><span className="font-monospace">{startedAt}</span></Col>
                </Row>
            }
            {exportedAt &&
                <Row className="mb-2">
                    <Col md={3} className="d-flex justify-content-end"><Form.Label>{t('order:historyForm.exportedDate')}</Form.Label></Col>
                    <Col md={3}><span className="font-monospace">{exportedAt}</span></Col>
                </Row>
            }
            {closedAt &&
                <Row className="mb-2">
                    <Col md={3} className="d-flex justify-content-end"><Form.Label>{t('order:historyForm.closedDate')}</Form.Label></Col>
                    <Col md={3}><span className="font-monospace">{closedAt}</span></Col>
                </Row>
            }
            {resignedAt &&
                <Row className="mb-2">
                    <Col md={3} className="d-flex justify-content-end"><Form.Label>{t('order:historyForm.resignedDate')}</Form.Label></Col>
                    <Col md={3}><span className="font-monospace">{resignedAt}</span></Col>
                </Row>
            }
        </Container>);
    }
}

export default withTranslation() (OrderHistoryComponent);
