import React from "react";
import { useParams, useOutletContext } from "react-router-dom";
import OrderComponent from "./OrderComponent";

const OrderRoute = () => {
    const { id } = useParams();
    const [timeZone] = useOutletContext();
    return <OrderComponent id={id} timeZone={timeZone} />;
};

export default OrderRoute;