import React from "react";
import {withTranslation} from "react-i18next";
import {Button, Col, Container, Form, Row, Stack} from "react-bootstrap";
import i18n from '../../messages/i18n';

class CommentsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { t } = this.props;
        const dateFormatOptions = {
            formatMatcher: "basic",
            year: 'numeric',
            month: 'numeric',
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZoneName: "short",
            timeZone: this.props.timeZone
        };

        return(<Container className="customForm">
            {this.props.data && this.props.data.map((comment, index) => (
                <Row className="mb-2" key={comment.id}>
                    <Form.Group className="comment" as={Col}>
                        <Form.Label>{index + 1}. <b>{Intl.DateTimeFormat(i18n.language, dateFormatOptions).format(new Date(comment.createdAt))}</b> {comment.authorName} {comment.authorSurname} ({comment.authorEmail})</Form.Label>
                        <Form.Control as="textarea" name="commentNote" value={comment.note} rows={4} disabled/>
                    </Form.Group>
                </Row>
            ))}
            {this.props.showAddCommentButton && (
                <Row>
                    <Stack direction="horizontal">
                        <Button onClick={this.props.onAddComment}>{t('order:button.addComment')}</Button>
                    </Stack>
                </Row>
            )}
        </Container>);
    }
}

export default withTranslation() (CommentsComponent);
