import React from "react";
import {withTranslation} from "react-i18next";
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import UserService from "../../services/UserService";

class ResetPasswordNotificationComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            email: "",
            error: null,
            success: null,
            validateForm: false,
            disableSendButton: false,
            showSpinner: false
        }
    }

    sendNotification = (e) => {
        e.preventDefault();

        this.setState({
            validateForm: true
        });

        if (e.currentTarget.checkValidity() === false) {
            return;
        }

        this.setState({
            error: null,
            disableSendButton: true,
            showSpinner: true
        });

        UserService.sendResetPasswordNotification(this.state.email).then(() => {
            this.setState({
                success: this.props.t("resetPassword:sendSuccess"),
                showSpinner: false
            });
        }).catch(() => {
            this.setState({
                error: this.props.t("resetPassword:sendError"),
                disableSendButton: null,
                showSpinner: false
            });
        });
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    render() {
        const { t } = this.props;

        return(<>
            <Container className="customForm"  style={{width: "900px", padding: "80px", marginTop: "100px"}}>
                <Row className="panel-heading">
                    <Col style={{textAlign: "center"}}><h4>{t('resetPassword:title')}</h4></Col>
                </Row>
                {this.state.error && (<><br/><Alert variant="danger">{this.state.error}</Alert></>)}
                {this.state.success && (<><br/><Alert variant="success">{this.state.success}</Alert></>)}
                <br/>
                <Form onSubmit={this.sendNotification} validated={this.state.validateForm} noValidate>
                    <Row>
                        <Col>
                            <Form.Control type="email" name="email" value={this.state.email} placeholder={t('common:email')} onChange={(event) => this.handleInputChange(event)} autoFocus required/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <Button type="submit" variant="primary" disabled={this.state.disableSendButton}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    hidden={!this.state.showSpinner}
                                />
                                {' ' + t('common:button.send')}
                            </Button>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Link to={'/'}>
                                {t('resetPassword:return')}
                            </Link>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>);
    }

}

export default withTranslation()(ResetPasswordNotificationComponent);
