import DataTable from "react-data-table-component"
import {withTranslation} from "react-i18next";
import React from "react";
import {useState} from 'react';
import { Button } from "react-bootstrap";


const CustomTable = (props) => {

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState([]);

  const paginationOptions = {
      pagination: true,
      paginationPerPage: 10,
      paginationRowsPerPageOptions: [10, 25, 50, 100],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectableRowsVisibleOnly: true,
      paginationComponentOptions:{
        rowsPerPageText: props.t('common:paginationTable.rowsPerPageText'), 
        rangeSeparatorText: props.t('common:paginationTable.rangeSeparatorText') , 
        selectAllRowsItemText: props.t('common:paginationTable.selectAllRowsItemText')
      }
  };

  const handleChange = ({ selectedRows }) => {
      setSelected(selectedRows)
  };
      
  const subHeaderComponent = (
      <div>
          <Button hidden={props.isHidden} 
              variant={props.variantName} 
              style={{marginTop: '10px'}}
              onClick={() => props.buttonAction(selected)}
          >
              {props.buttonName}
          </Button>
      </div>
  ); 

  const handlePerRowsChange = async (newPerPage, page) => {
    if(props.paginationServer === true && newPerPage !== perPage) {
      console.log("CustomTable.handlePerRowsChange(" + newPerPage + "," + page + ")");
      await props.getData(page, newPerPage);
      setPerPage(newPerPage);
    }
    
  };

  const handlePageChange = async (page) => {
    if(props.paginationServer === true){
      console.log("CustomTable.handlePageChange(" + page + ")");
      await props.getData(page, perPage);
      setCurrentPage(page);
    }
  };

  return (
      
      <DataTable
          columns={props.columns}
          data={props.data}
          noDataComponent={<div style={{ padding: '24px' }}>{props.t('common:noDataComponent')}</div>}

          selectableRows={props.selectableRows}
          onSelectedRowsChange={handleChange}

          fixedHeader={props.fixedHeader}
          fixedHeaderScrollHeight={props.fixedHeaderScrollHeight}
          
          progressPending={props.loading}
          progressComponent={<div style={{ fontSize: '24px', fontWeight: 700, padding: '24px' }}>{props.t('common:loading')}</div>}

          {...paginationOptions}

          paginationServer={props.paginationServer} // włącz tryb serwerowy paginacji
          paginationTotalRows={props.totalRows} // całkowita liczba wierszy w bazie danych
          paginationDefaultPage={currentPage} // aktualna strona
          onChangeRowsPerPage={handlePerRowsChange}//zmiana liczby wierszy na stronie
          onChangePage={handlePageChange} // obsługa zmiany strony
          
          subHeader={props.showHeader}
          subHeaderComponent={subHeaderComponent}
          striped
          highlightOnHover
          customStyles={{
              rows: {
                style: {
                  fontSize: '14px',
                },
              },
              headCells: {
                style: {
                  fontSize: '16px',
                },
              },
              pagination: {
                  style: {
                      fontSize: '14px',
                  }},
          }}
          dense
          noHeader
          responsive="true"
      />
  );
}
export default withTranslation() (CustomTable);
