import React from "react";
import ReportService from "../../services/ReportService";
import DownloadService from "../../services/DownloadService";
import {withTranslation} from "react-i18next";
import {Button, Container, Stack} from "react-bootstrap";
import SpinnerModal from "../../commons/modal/SpinnerModal";
import ErrorModal from "../../commons/modal/ErrorModal";
import {Navigate} from "react-router-dom";
import {getCurrentUser} from "../../utils/storageUtils";

class ReportsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSpinnerMessage: null,
            showErrorMessage: null
        }
    }

    handleGetFullReport = () => {
        this.showSpinner(this.props.t('common:modalMessage.getData'));
        ReportService.getFullReport().then((success) => {
            this.hideSpinner();
            DownloadService.downloadFromResponse(success);
        }, (error) => {
            this.showError(error.response.data + " (" + error.response.status + ")");
        });
    }

    showSpinner = (message) => {
        this.setState({
            showSpinnerMessage: message,
            showErrorMessage: null
        });
    }

    showError = (message) => {
        this.setState({
            showSpinnerMessage: null,
            showErrorMessage: message
        });
    }

    hideSpinner = () => {
        this.setState({
            showSpinnerMessage: null
        });
    }

    hideError = () => {
        this.setState({
            showErrorMessage: null
        })
    }

    render() {
        const currentUser = getCurrentUser();
        if (!currentUser || !currentUser.reporter) return <Navigate to="/" replace />;

        const { t } = this.props;
        return(<>
            <ErrorModal modalShow={this.state.showErrorMessage != null} close={this.hideError} body={this.state.showErrorMessage} title={t('common:modalMessage.errorTitle')}/>
            <SpinnerModal modalShow={this.state.showSpinnerMessage != null} title={this.state.showSpinnerMessage}/>
            <Container className="customForm">
                <h4>{t('reports:header')}</h4>
                <br/>
                <Stack direction="horizontal" gap={3}>
                    <Button onClick={this.handleGetFullReport}>{t('reports:button.getFullReport')}</Button>
                </Stack>
            </Container>
        </>);
    }
}

export default withTranslation()(ReportsComponent);