import React from "react";
import { NavLink } from "react-router-dom";
import {Nav, Navbar, Container, Form, Button} from 'react-bootstrap';
import {withTranslation} from "react-i18next";
import {getCurrentUser, getInstanceInfo} from "../utils/storageUtils";
import {getTimeZoneList} from "../utils/timeZoneUtils";

const NavbarComponent = (props) => {
    const currentUser = getCurrentUser();
    const instanceInfo = getInstanceInfo();

    return(
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/"><img src="/favicon-32x32.png" className="d-inline-block align-top" alt="PI"/>{props.t('common:appTitle')}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        {currentUser && (<>
                            <Nav.Item>
                                <NavLink to={'orders'} className={({isActive, isPending}) => (isActive ? "active mx-1 my-1" : isPending ? "pending mx-1 my-1" : "mx-1 my-1")}>
                                    {props.t('sidebar:orders')}
                                </NavLink>
                            </Nav.Item>
                            {currentUser.operator && (
                                <Nav.Item>
                                    <NavLink to={'import'} className={({isActive, isPending}) => (isActive ? "active mx-1 my-1" : isPending ? "pending mx-1 my-1" : "mx-1 my-1")}>
                                        {props.t('sidebar:import')}
                                    </NavLink>
                                </Nav.Item>
                            )}
                            {currentUser.reporter && (
                                <Nav.Item>
                                    <NavLink to={'reports'} className={({isActive, isPending}) => (isActive ? "active mx-1 my-1" : isPending ? "pending mx-1 my-1" : "mx-1 my-1")}>
                                        {props.t('sidebar:reports')}
                                    </NavLink>
                                </Nav.Item>
                            )}
                            {currentUser.operator && (
                                <Nav.Item>
                                    <NavLink to={'assign'} className={({isActive, isPending}) => (isActive ? "active mx-1 my-1" : isPending ? "pending mx-1 my-1" : "mx-1 my-1")}>
                                        {props.t('sidebar:assignDealers')}
                                    </NavLink>
                                </Nav.Item>
                            )}
                        </>)}
                        {instanceInfo && instanceInfo.platform === "ST" && (
                            <Nav.Item>
                                <Form className="mx-1 my-1" >
                                    <Form.Select size="sm" value={props.lang} onChange = {(event) => (props.onChangeLang(event.target.value))}>
                                        <option value="pl" key="pl">Polski</option>
                                        <option value="en" key="en">English</option>
                                    </Form.Select>
                                </Form>
                            </Nav.Item>
                        )}
                        <Nav.Item>
                            <Form className="mx-1 my-1">
                                <Form.Select size="sm" value={props.timeZone} onChange = {(event) => (props.onTimeZoneChange(event.target.value))}>
                                    {getTimeZoneList().map(tz => (
                                        <option value={tz} key={tz}>{tz}</option>
                                    ))}
                                </Form.Select>
                            </Form>
                        </Nav.Item>
                        {currentUser && (<>
                            <Nav.Item>
                                <div style={{padding: "0.5rem"}} className="text-secondary mx-1 my-1">{currentUser.firstName} {currentUser.lastName}</div>
                            </Nav.Item>
                            <Nav.Item>
                                <Button size="sm" variant="danger" className="mx-1 my-1" onClick={props.onLogout}>{props.t('sidebar:logout')}</Button>
                            </Nav.Item>
                        </>)}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default withTranslation() (NavbarComponent);