import HttpService from "../http/HttpService";

class DeviceService {

    getLocationDevices(orderId) {
        return HttpService.get('/devices/locationDevices/' + orderId);
    }

    getVendorFirmwareVersions(orderId) {
        return HttpService.get('/devices/firmwareVersions/' + orderId);
    }

}
// eslint-disable-next-line
export default new DeviceService();