import React from "react";
import {withTranslation} from "react-i18next";
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import UserService from "../../services/UserService";

class ResetPasswordComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            password: "",
            error: null,
            success: null,
            validateForm: false,
            showButton: true
        }
    }

    resetPassword = (e) => {
        e.preventDefault();
        this.setState({
            validateForm: true
        });

        if (e.currentTarget.checkValidity() === false) {
            return;
        }

        this.setState({
            error: null,
            disableSendButton: true
        });

        UserService.resetPassword(this.props.token, this.props.email, this.state.password).then(() => {
            this.setState({
                success: this.props.t("resetPassword:changeSuccess"),
                showButton: false
            });
        }).catch(error => {
            this.setState({
                error: error.response.data,
                disableSendButton: null
            });
        });
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    togglePasswordVisibility = () => {
        var x = document.getElementById("password");
        x.type = x.type === "text" ? "password" : "text";
    }

    render() {
        const { t } = this.props;

        return(<>
            <Container className="customForm"  style={{width: "900px", padding: "80px", marginTop: "100px"}}>
                <Row className="panel-heading">
                    <Col style={{textAlign: "center"}}><h4>{t('resetPassword:title')}</h4></Col>
                </Row>
                {this.state.error && (<Alert variant="danger" className="my-4">{this.state.error}</Alert>)}
                {this.state.success && (<Alert variant="success" className="my-4">{this.state.success}</Alert>)}
                <Form onSubmit={this.resetPassword} validated={this.state.validateForm} noValidate>
                    <Row className="mt-4">
                        <Col>
                            <Form.Control type="password" name="password" id="password" value={this.state.password} placeholder={t('resetPassword:passwordPlaceholder')} onChange={(event) => this.handleInputChange(event)} autoFocus required/>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="d-flex justify-content-start">
                            <Form.Check onClick={this.togglePasswordVisibility}/> <div class="ms-lg-2">{t('resetPassword:showPassword')}</div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            {this.state.showButton && (
                                <Button type="submit" variant="primary" disabled={this.state.disableSendButton}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        hidden={!this.state.disableSendButton}
                                    />
                                    {' ' + t('resetPassword:changePasswordButton')}
                                </Button>
                            )}
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Link to={'/'}>
                                {t('resetPassword:return')}
                            </Link>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>);
    }

}

export default withTranslation()(ResetPasswordComponent);
