import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider, Navigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import './css/index.css';
import App from './App';
import ErrorPage from './components/error/error-page';
import OrdersComponent from './components/orders/OrdersComponent';
import ImportComponent from './components/orders/ImportComponent'
import ReportsComponent from './components/reports/ReportsComponent'
import AssignDealersComponent from "./components/assignDealers/AssignDealersComponent";
import {I18nextProvider} from "react-i18next";
import i18n from "./messages/i18n";
import OrderRoute from "./components/orders/OrderRoute";
import ResetPasswordNotificationComponent from "./components/resetPassword/ResetPasswordNotificationComponent";
import ResetPasswordRoute from "./components/resetPassword/ResetPasswordRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <div><App /></div>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "assign",
        element: <AssignDealersComponent/>,
      },
      {
        path: "orders",
        element: <OrdersComponent/>
      },
      {
        path: "orders/:id",
        element: <OrderRoute/>
      },
      {
        path: "reports",
        element: <ReportsComponent/>
      },
      {
        path: "import",
        element: <ImportComponent/>
      },
      {
        path:"*",
        element:<Navigate to="/" replace />
      }
    ],
  },
  {
    path: "resetPassword",
    element: <ResetPasswordNotificationComponent/>
  },
  {
    path: "resetPasswordToken/:email/:token",
    element: <ResetPasswordRoute/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </React.StrictMode>
);
