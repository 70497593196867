import React from "react";
import {withTranslation} from "react-i18next";
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import i18n from "i18next";
import {Link} from "react-router-dom";
import UserService from "../../services/UserService";
import {removeAuthData, setAuthData} from "../../utils/storageUtils";

class LoginComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            error: null,
            validateForm: false
        }
    }

    login = (e) => {
        e.preventDefault();
        this.setState({ validateForm: true });
        if (e.currentTarget.checkValidity() === false) return;

        removeAuthData();

        this.setState({
            error: null,
            disableLoginButton: true
        });

        UserService.login(this.state.email, this.state.password).then(result => {
            this.handleLoginSuccess(result.data);
        }).catch((error) => {
            this.handleLoginFailure(error.data);
        });
    }

    handleLoginFailure = (data) => {
        this.setState({
            error: this.props.t("common:loginError"),
            disableLoginButton: null
        });
    }

    handleLoginSuccess = (data) => {
        // set user preferred lang
        const currentUser = data.currentUser;
        if (currentUser.attributes) {
            if (currentUser.attributes.lang) i18n.changeLanguage(currentUser.attributes.lang[0]);
            if (currentUser.attributes.timeZone != null) this.props.onTimeZoneChange(currentUser.attributes.timeZone[0]);
        }
        // save data
        setAuthData(data);
        // jump to guts
        let href = "/orders";
        if (currentUser.operator) href = "/assign";
        else if (currentUser.reporter) href = "/reports";
        document.location.href = href;
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };
   
    render() {
        const { t } = this.props;
       
        return(<>
            <Container className="customForm"  style={{width: "900px", padding: "80px", marginTop: "100px"}}>
                <Row className="panel-heading">
                    <Col style={{textAlign: "center"}}><h4>{t('common:loginPanel')}</h4></Col>
                </Row>
                {this.state.error && (<><br/><Alert variant="danger">{this.state.error}</Alert></>)}
                <br/>
                <Form onSubmit={this.login} validated={this.state.validateForm} noValidate>
                    <Row>
                        <Col>
                            <Form.Control type="email" name="email" value={this.state.email} placeholder={t('common:email')} onChange={(event) => this.handleInputChange(event)} autoFocus required/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <Form.Control type="password" name="password" value={this.state.password} placeholder={t('common:password')} onChange={(event) => this.handleInputChange(event)} required/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <Button type="submit" variant="primary" disabled={this.state.disableLoginButton}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    hidden={!this.state.disableLoginButton}
                                />
                                {' ' + t('common:button.login')}
                            </Button>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Link to={'resetPassword'}>
                                {t('resetPassword:forgotPassword')}
                            </Link>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>);
    }

}

export default withTranslation()(LoginComponent);
