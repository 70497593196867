import i18n from "i18next";
import reactStringReplace from "react-string-replace";

export function formatErrorMessage(message, status) {
    const t = i18n.t;
    if (message) {
        if (message.includes("not-null property references a null"))
            return t("common:modalMessage.nonNullProperty")
        return message;
    }
    if (status) {
        if (status === 403) return t("common:modalMessage.error403");
        return status;
    }
    return t("common:modalMessage.errorUnknown");
}

export function removeHtmlTags(text) {
    if (!text) return text;
    return reactStringReplace(text, /(<\w+\/?>)/g, (match, i) => ("")).join("");
}

export function composeVendorFirmwareVersionsMessage(header, versionsResponse) {
    if (!versionsResponse) return header;

    // map all models and models' versions
    const models = {};
    versionsResponse.hw_ver_rad3_version_info.forEach(e => {
        const modelName = e.company + " " + e.model;
        let model = models[modelName];
        if (model == null) {
            model = {
                rad: {},
                pos: {}
            };
            models[modelName] = model;
        }
        if (e.compatible_versions) e.compatible_versions.forEach(v => { if (v) model.rad[v] = v; });
        if (e.mpos_values) e.mpos_values.forEach(v => { if (v) model.pos[v] = v; });
    });

    // add versions to message
    let msg = header;
    Object.keys(models).forEach(modelName => {
        let model = models[modelName];
        let firstRad = true;
        let firstPos = true;
        msg += "<br>&bullet; " + modelName;
        if (Object.keys(model.rad).length > 0) {
            msg += ", wersje RAD: ";
            Object.keys(model.rad).forEach(v => { msg += (firstRad ? "" : ", ") + v; firstRad = false; });
        }
        if (Object.keys(model.pos).length > 0) {
            msg += ", wersje POS: ";
            Object.keys(model.pos).forEach(v => { msg += (firstPos ? "" : ", ") + v; firstPos = false; });
        }
    });
    return msg;
}
